import { OFFER_STORE_PRODUCTLIST,OFFER_STORE_BANNER } from "../Constants";

export const offerStoreApi = async (id) => {
  let payload;
  payload = new URLSearchParams({
    storeId: id,
  });

  const response = await fetch(OFFER_STORE_PRODUCTLIST, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: payload,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
 // console.log(data);
  if (data.Status) return data;
  else return "";
};


export const offerStoreBanner = async () => {


  
    const response = await fetch(OFFER_STORE_BANNER, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      
    });
  
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  
    const data = await response.json();
    console.log(data);
    if (data.Status) return data;
    else return "";
  };