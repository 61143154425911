import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { BrandList } from '../../common/brands/brandApi';
import Banner from "./brandBanner";
import "./brandDetail.css";
import PageNotFoundLogo from '../../../assests/image/PageNotFound.png'
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";
import BrandFilter from "../brands/brandFilter";
import { BRAND_DETAILS } from '../../../Constants';
import BrandMetaTag  from "../../layout/metaTag/metaTagComp";
import LoadingCard from '../loader/loadingCard';
import { Link } from 'react-router-dom';
import CustomLoader from '../customLoader/customLoader';
import ProductList from '../../layout/product/fetchProductList';
const BrandDetail = ({ setCountItems,brandsDetailsData }) => {
  const { brandName } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [brandDetails, setBrandDetails] = useState(null);
  const [brandProductsListData, setBrandProductsListData] = useState([]);
  //const [brandsDetailsData, setBrandsDetailsData] = useState(null);
  const [brands, setBrands] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading ,setIsLoading] = useState(true)
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const hasFetched = useRef(false);
  const previousId = useRef(id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

//   useEffect(() => {
//     const fetchBrandsFromApi = async () => {
//       try {
//         const url = BRAND_DETAILS;
//         const payload = {
//           BrandId: id,
//           Pagination: page,
//           ApplicationType : "web"
//         };
//         const response = await fetch(url, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/x-www-form-urlencoded',
//           },
//           body: new URLSearchParams(payload).toString(),
//         });

//         if (!response.ok) {
//           throw new Error(`HTTP error! status: ${response.status}`);
//         }

//         const brandsData = await response.json();
//        // console.log(brandsData?.BrandDetails?.BrandName.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s+/g, "-").toLowerCase())
//         if(brandsData?.BrandDetails?.BrandName?.replace(/--+/g, "")?.replace(/[^a-zA-Z0-9 -]/g, "").replace(" ","-")?.replace(/\s+/g, "-").toLowerCase() === brandName)
//   {     
//     setBrandsDetailsData(brandsData?.BrandDetails);
//         setBrandProductsListData((prevData) => {
//           const newData = brandsData.Result[0] || [];
//           const mergedData = [...prevData, ...newData];
//           const uniqueData = mergedData.filter(
//             (item, index, self) =>
//               index === self.findIndex((t) => t.id === item.id)
//           );
//           setHasMore(brandsData.Result[0]?.length === 10);
//           return uniqueData;
        
//         });}
//         else{
//           return ""
//         }

       
//       } catch (error) {
//         console.error('Error fetching brands:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     if (id) {
//       if (previousId.current !== id) {
//         // ID has changed, clear the list data
//         previousId.current = id;
//         setBrandProductsListData([]);
//         setPage(1); // Reset page number when ID changes
//       }
//       fetchBrandsFromApi();
//     }
//     setTimeout(() => {  setIsLoading(false)},600)   //showing loader so that data at back and load
  
//   }, [id, page]);

//   const loadMoreBrandProducts = () => {
//     if (hasMore && !hasFetched.current && page <= 5) {  // Stops fetching if page equals 5
//       setPage((prev) => prev + 1);
//     }
//   };
//   useEffect(() => {
//     const handleScroll = () => {
//       const { scrollTop, clientHeight, scrollHeight } = document.documentElement;

//       if (scrollTop + clientHeight >= scrollHeight - 320) { // A little buffer (5px)
//         loadMoreBrandProducts();
//       }
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll); // Clean up the event listener on unmount
//     };
//   }, [loading, hasMore]);

// if(isLoading){
//   document.querySelector(".opacity__main__container")?.classList.remove("active");
// }
//console.log(brandsDetailsData)
  return (
    <>
       <BrandMetaTag />
       {/* {isLoading && <CustomLoader/>  } */}
        {brandsDetailsData  && <div className="brand-detail">
        {/* Display Brand Banner */}
          <Banner brandDetails={brandsDetailsData} /> 
        {/* Display Brand Logo Section */}
        <div className="brand__detail__Wrapper">
          <div className="">
            <div className='brand__detail__container'>
              <div className="brand__image__content">
                <img src={brandsDetailsData?.BrandLogo} alt="brand section" />
              </div>
              <div className="brand__text__content">
                <div className="brand__heading">
                  <h1>{brandsDetailsData?.BrandName}</h1>
                </div>
                <div className="brand__paragraph">
                  {brandsDetailsData?.BrandDescription
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Display Brand Breadcrumb */}
         {/* <div className="brand__detail__breadcrumb page-width">
         <Breadcrumb />
        </div>  */}

         {/* <div className="brand__details__filter" style={{ marginTop: "30px" }}>
          {/* <BrandFilter 
            brandDetails={brandDetails} 
            setCountItems={setCountItems} 
            BrandProductsListData={brandProductsListData} 
            setLoading={setLoading} 
            loading={loading} 
          /> 
          <ProductList/>
        </div> */}
      </div>}  
      {/* {!brandsDetailsData && !isLoading && <div className='page-not-found-logo page-width'>
        <img src={PageNotFoundLogo}/>
        <Link className='btn_primary' to={'/'}>Continue Shopping</Link>
        </div>}  */}
        
    </>
  );
};

export default BrandDetail;
