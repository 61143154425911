import React, { useState,useRef,useEffect } from "react";
import "./unfitStore.css";
import GoogleMapReact from "google-map-react";
import storePinIcon from "../../assests/image/storePinIcon.png";
import { fetchMarkerList, fetchStoreList } from "./fetchUnfitStoreApi";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedStore,setMarkerLists } from "../../redux/slice";
import { useNavigate } from "react-router-dom";

const GoogleMapsDisplay = () => {
	const isMounted = useRef(false);
	const [markerList,setMarkerList] = useState([]);
  const [center, setCenter] = useState({ lat: '', lng: '',zoom:7 });

	const city = useSelector((state) => state.filters.selectedCity); // Access state
	const mList = useSelector((state) => state.filters.markerList); // Access state
  const selectedStore = useSelector((state) => state.filters.seletctedStore); // Access state

	const dispatch = useDispatch(); // Access dispatch function
  const  navigate = useNavigate()
  
let locations 
useEffect(() => {

	if(mList.length > 0){
		locations = mList && mList.map(store => ({
			id: store.id,
			name: store.StoreName,
			lat:  parseFloat(store.Latitude),
			lng:  parseFloat(store.Longitude)
		 }))
		// console.log("locations :",locations[0])
		 setMarkerList(locations);
     let c= city =="All" ? 7 : 12
     setCenter({ lat:locations[0].lat, lng: locations[0].lng,zoom :c})

	}


},[mList])

 

//console.log(center)
let response
  const loadProducts = async () => {
    //setLoading(true);
    try {
   // console.log(city)
		response = await fetchStoreList(city);
	  //console.log("response : ",response)
	   dispatch(setMarkerLists(response))

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
     // setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {
 
     // Set isMounted to true after the first render
      loadProducts(); // Call loadProducts once on initial mount

  }, [city]);







  const [mapInstance, setMapInstance] = useState(null);
  const [mapsInstance, setMapsInstance] = useState(null);

  useEffect(() => {
    if (mapInstance && mapsInstance && markerList) {
      // Remove existing markers if any (optional)
      //console.log(markerList)

      // Create new markers
       markerList.map((location) => {

       // console.log("Hi",location)
        const marker= new mapsInstance.Marker({
          position: { lat: location.lat, lng: location.lng },
          map: mapInstance,
          title: location.name,
          icon: {
            url: storePinIcon,
            scaledSize: new mapsInstance.Size(60, 45),
          },
        });


        marker.addListener('click', () => {
         // console.log(location.id); // Call the function on marker click
          dispatch(setSelectedStore(location.id))
        });
       // console.log(selectedStore)
      });

      // Optionally store markers in state if you want to manage them
     
    }
    //console.log(markerList)
  }, [markerList, mapInstance, mapsInstance]); // Re-run when markerList changes

  const handleApiLoaded = (map, maps) => {
  console.log("Google API Loaded:", markerList);
    setMapInstance(map);
    setMapsInstance(maps);
  };


  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
  
    }
    else if(selectedStore){
      console.log(selectedStore)
      navigate(`/unfit-store/Surat?storeId=${selectedStore}`)
    }
  },[selectedStore])
  
  
  return (
    <div  className="google-map-container">
{  mList.length>0 && center.lat ?    <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC_v4joUTw8hq8i_3U9ikxXn4zSNsJUafM" }} // Replace with your API key
        center={center}
        zoom={center.zoom}
        yesIWantToUseGoogleMapApiInternals={true} // Enable access to Google Maps API internals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)} // Pass map and maps to the handler
      >

        
      </GoogleMapReact> : <div></div>}
    </div>
  );
};

export default GoogleMapsDisplay;
