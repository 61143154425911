import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRedirectionUrl } from './fetchRedirectionUrl';

const  RedirectionPage = () => {


	const [USIN,setUSIN] = useState();
	const  navigate = useNavigate()

	// Retrieve and decode query parameters

  
	let url = window.location.href;
	const segments = url.split("/");
    const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  // Retrieve and decode query parameters
  const ProductId = queryParams.get("productid");
  console.log(ProductId)
	let USINData = segments[4];
	if (USINData === "undefined") {
	  USINData = "";
	}
  
	// Extract the flavor and size from the appropriate segments
	const flavor = decodeURIComponent(segments[segments.length - 2]);
	const size = decodeURIComponent(segments[segments.length - 1].split("?")[0]);
  
	let Variant1 = flavor
	  ?.replace(/-/g, " ")
	  ?.replace(/\b\w/g, (char) => char.toUpperCase());
	let Variant2 = size
	  ?.replace(/-/g, " ")
	  ?.replace(/\b\w/g, (char) => char.toUpperCase());
  
	let Variant1Val =  Variant1?.replace(/\+/g, " ") ;
	let Variant2Val =  Variant2?.replace(/\+/g, " ") 
  console.log(Variant1Val,Variant2Val)


  let response
  const loadCity = async () => {
    //setLoading(true);
    try {
   
		response = await fetchRedirectionUrl(ProductId);
	  console.log("response : ",response.length)

	  if(Object.keys(response).length == 1){
		setUSIN(response.USIN)
	  }
	  else if(Object.keys(response).length == 0){
		handleEmptyResponse()
	  }
	  

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
     // setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {

    loadCity();

  },[])
  const handleRedirectWithReload = () => {
	window.location.href = url.replace("undefined",USIN);  // This will cause a full page reload
  };

  const handleEmptyResponse = () => {
	
  };
  useEffect(() => {
	console.log(USIN)
	if(USIN){
		handleRedirectWithReload()
	}
  },[USIN])

  return (
	<div>
	  
	</div>
  )
}

export default RedirectionPage
