import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./login.css";

import "slick-carousel/slick/slick-theme.css";
import Cookies from "js-cookie";

import { cross_black } from "../../../../utils/image";
import {
  CHECK_USER_NEW,
  fetchUserData,
  LOGIN_NEW,
} from "../../../../Constants";
import AddToCartList from "../../cart/addToCartList";
import moengage from "@moengage/web-sdk";

const Login = ({
  displayLoginPages,
  setDisplayLoginPages,
  setDisplayLoginPagesH,
  displayLoginPagesH,
}) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isValidMobile, setIsValidMobile] = useState(false);
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const regexPattern = /^\+91\d{10}$/;
  let modal = document.getElementById("myModal");
  let modall = document.getElementById("myModall");
  let span;

  useEffect(() => {
    span = document.getElementsByClassName("close")[0];
  }, []);

  const handleMobileNumberChange = (e) => {
    let { value } = e.target;

    value = value.replace(/\D/g, "");
    value = value.slice(0, 10);
    setMobileNumber(value);
    setIsInputEmpty(value.trim() === "");
    setIsValidMobile(value.length === 10 && regexPattern.test(`+91${value}`));
    setOtpSent(false); // Reset OTP sent state when mobile number changes
  };



  const otpLogin = async (mobileNumber) => {
    try {
      
      const payload = new URLSearchParams({ MobileNo: `+91${mobileNumber}` });
      const response = await fetch(LOGIN_NEW, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      setOtpSent(true);
      setError("");
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to send OTP. Please try again.");
    }
  };

  const checkApi = async (mobileNumber, otp) => {
    try {
     
      const payload = new URLSearchParams({
        MobileNo: `+91${mobileNumber}`,
        Code: otp,
      });
      const response = await fetch(CHECK_USER_NEW, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

     
      Cookies.set("Otp", JSON.stringify({ MobileNo: mobileNumber, otp: otp }), {
        expires: 1,
      }); // Cookie expires in 1 day

      if (data.Result && data.Result.length > 0) {
        const result = data.Result[0];
        console.log(mobileNumber,result.Email , result.Id ?? result.id,result.Name , result.userName)
        Cookies.set(
          "userData",
          JSON.stringify({
            id: result.Id ?? result.id,
            userName: result.Name ?? result.userName,
            email: result.Email,
            gender: result.Gender,
            MobileNo: `${mobileNumber}`,
            otp: otp,
          }),
          { expires: 1 }
        ); // Cookie expires in 1 day
        moengage
      .add_unique_user_id(`+91${mobileNumber}`)
      .then();
           moengage.add_first_name(`${result.Name.split(" ")[0] ?? result.userName.split(" ")[0]}`);
     moengage.add_last_name(`${result.Name.split(" ")[1] ?? result.userName.split(" ")[1]}`);
   moengage.add_mobile(mobileNumber);
     moengage.add_email(`${result.Email}`);
   moengage.add_user_name(`${result.Name ?? result.userName}}`); // Full name for user
     moengage.add_gender(`${result.Gender}`);
        moengage.track_event("Web_login", {
          "ID": `+91${mobileNumber}` ,  
          "Email id": result.Email,
          "Customer Id":result.Id ?? result.id,
          
    
          });

        if(result.Id){
          AddToCartList();
        }
      }



      return { isVerified: data.Status, result: data.Result };
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const handleSendOTP = () => {
    if (isValidMobile) {
      otpLogin(mobileNumber);
    }
   
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if(isInputEmpty){
      document.querySelector(".validation-error.button__click__otp")?.classList?.remove("hide");
    }

    if (mobileNumber && otp) {
      try {
        const { isVerified = "true" } = await checkApi(
          mobileNumber,
          otp
        );
console.log(isVerified)
        if (isVerified) {
          if (displayLoginPages) 
            {
            setDisplayLoginPages({
              ...displayLoginPages,
              displayLogin: false,
              displayAccountPage: true,
            });
            //AddToCartList();
          } else if (displayLoginPagesH && !fetchUserData()?.id) {  //New user from Cart page's login
            setMobileNumber('');
            setOtp('')
            modall.style.display = "none";
            setDisplayLoginPagesH({
              displayLoginPagesH: false,
            });
            navigate("/account");
          }else if(displayLoginPagesH && fetchUserData()?.id ){ //verified user from Header's login 
            setMobileNumber('');
            setOtp('')
            modall.style.display = "none";
            setDisplayLoginPagesH({
              displayLoginPagesH: false,
            });
            AddToCartList();
            navigate("/");
          }
           else if (fetchUserData()?.id) {  //verified user from Cart page's login 

            navigate("/");
          } else {
            console.log("New user from Header's Login")
            navigate("/account");   //New user from Header's Login
          }

         
        } else {
          setError("Invalid OTP. Please try again.");
        }
      } catch (error) {
        console.error("Login error:", error);
        setError("Failed to verify OTP. Please try again.");
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById('otpButton').click();
    }
};
const handleKeyLogin = (event) => {
  if (event.key === 'Enter') {
      event.preventDefault();
      document.getElementById('loginButton').click();
  }
};

 

  const handleClose = () => {
    if (displayLoginPages) {
      modal.style.display = "none";
      setDisplayLoginPages({
        ...displayLoginPages,
        displayLogin: false,
      });
    } else if (displayLoginPagesH) {
      modall.style.display = "none";
      setDisplayLoginPagesH({
        displayLoginPagesH: false,
      });
    } else {
      navigate("/");
    }
  };

  return (
    <div className="login__wrapper">
      <div className="login__container flex gap10">
        <div className="login__left__content">
          <div className="login__main__content">
            <div className="login__heading">
              <div className="loginName">Login</div>
              <span className="close" onClick={handleClose}>
                <img src={cross_black} />
              </span>
            </div>
            <div className="login__sub__heading">
              Login to access your unFIT account
            </div>

            <div className="login__phone__box">
              <div className="login__phone__head">Enter Mobile Number</div>
              <div
                className={`login-otp ${
                  !isValidMobile && !isInputEmpty ? "invalid" : ""
                }`}
              >
                <div className="prefix">+91 </div>
                <input
                  type="text"
                  placeholder="Enter 10-digit number"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                  onKeyDown={handleKeyDown}
                />
                <button
                  type="button"
                  placeholder="Enter OTP"
                  className="orange"
                  onClick={handleSendOTP}
                  disabled={isInputEmpty || !isValidMobile || otpSent}
                  id="otpButton"
                >
                  {otpSent ? "OTP Sent" : "Send OTP"}
                </button>
              </div>
              {!isValidMobile && !isInputEmpty &&(
                <span className="validation-error">
                  Please enter a valid 10-digit mobile number
                </span>
              )}

              {isInputEmpty &&(
                <span className="validation-error button__click__otp hide">
                  Please enter a 10-digit mobile number
                </span>
              )}
            </div>
            <div className="login__phone__box">
              <div className="login__phone__head">Enter OTP</div>
              <input
                type="number"
                  placeholder="Enter OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                onKeyDown={handleKeyLogin}
              />
              <div
                onClick={handleLogin}
                className="login__submit"
                disabled={!mobileNumber || !otp}
                id="loginButton"
              >
                Login
              </div>
            </div>
            {error && <span className="validation-error">{error}</span>}

            {displayLoginPages && !displayLoginPages.displayLogin && (
              <div className="login__paragraph">
                Don’t have an account?{" "}
                <Link to="/register">
                  <span className="orange_color"> Register </span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
