import React, { useEffect, useState } from "react";
import WebBanner from "../../../assests/image/WebBanner.jpg";
import AppBanner from "../../../assests/image/AppBanner.jpg";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import moengage from "@moengage/web-sdk";


const BannerCard = ({ product,storeName,index}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 400px)" });
  const isDesktop = useMediaQuery({ query: "(min-width: 400px)" });
  const [url,setUrl] = useState("")


  const loadUrl = (type) => {

    switch (type) {
      case 'offer_store':
        
          setUrl(`/offer_store?id=${product?.NavigationTypeId}`)
          
         
          break;

      case 'brand':
          
          setUrl(`/brands/` + `${(product?.BrandName || 'default-brand')}`?.replace(/--+/g, "")?.replace(/[^a-zA-Z0-9 -]/g, "")?.replace(" ","-")?.replace(/\s+/g, "-").toLowerCase() + `?id=${product?.BrandId || 0}`)
         
          break;

      case 'parent_category':
          
          setUrl(`/category/${product?.ParentCategoryName}`)
         
          break;

      case 'sub_category':
          
          
          setUrl(`/category/${product?.ParentCategoryName.replace(/%20/g, "+")?.replace("%26","&")}/${product?.SubCategoryName?.replace(/%20/g, "+")?.replace("%26","&")}?id=${product?.SubCategoryId}`)

         
          break;

      case 'product':
           
            const variant1 = product?.Variant1Value.replace(/\s+/g, '+');
            const variant2 = product?.Variant2Value.replace(/\s+/g, '+');
            const prodName = product?.ProductName
            ?.replace(/[^a-zA-Z0-9.]+/g, "-") // Replace non-alphanumeric characters (except periods) with hyphens
            ?.replace(/-+/g, "-")             // Replace multiple consecutive hyphens with a single hyphen
            ?.replace(/^-|-$/g, "")           // Trim leading or trailing hyphens
            ?.toLowerCase();                  // Convert to lowercase
          
            setUrl(`/product/${product?.USIN}/${prodName}/${variant1}/${variant2}?productid=${product?.ProductId}`);
      
            // Additional actions for 'pause'
            
            break;

      default:
         
          break;
  }


  }

useEffect(() => {

  loadUrl(product?.NavigationType);


},[product])


const handleBannerClick = () => {
  console.log(product)
  let urlT;
  let bannerDataItem= product
  if(bannerDataItem.NavigationType === "offer_store"){
  

    moengage.track_event("Offer_Store_Banner_Viewed", {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
         "Store Name" : bannerDataItem.OfferTitle ,
         "Store ID": bannerDataItem.TypeId,
         "Index" : index,
         "Screen Name": storeName+" Advertistement Banner "
       });

  }
  else if(bannerDataItem.NavigationType === "brand"){


      moengage.track_event("Offer_Store_Banner_Viewed", {
          "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
           "Brand Name" : bannerDataItem.BrandName ,
           "Banner URL": bannerDataItem.WebImage,
           "Index" : index,
           "Screen Name":storeName+" Advertistement Banner "
         });
    
  }
  else if(bannerDataItem.NavigationType === "parent_category"){

      moengage.track_event("Offer_Store_Banner_Viewed", {
          "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
           "Category Name" : bannerDataItem.ParentCategoryName ,
           "Banner URL": bannerDataItem.WebImage,
           "Index" : index,
           "Screen Name":storeName+" Advertistement Banner "
         });

  }
  else if(bannerDataItem.NavigationType === "product"){
    const variant1 = bannerDataItem.Variant1Value.replace(/\s+/g, '+');
    const variant2 = bannerDataItem.Variant2Value.replace(/\s+/g, '+');
    const prodName = bannerDataItem?.ProductName
    ?.replace(/[^a-zA-Z0-9.]+/g, "-") // Replace non-alphanumeric characters (except periods) with hyphens
    ?.replace(/-+/g, "-")             // Replace multiple consecutive hyphens with a single hyphen
    ?.replace(/^-|-$/g, "")           // Trim leading or trailing hyphens
    ?.toLowerCase();                  // Convert to lowercase
  

    moengage.track_event("Offer_Store_Banner_Viewed", {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
        "Product Name" : bannerDataItem?.ProductName,
        [bannerDataItem.Variant1Name ? bannerDataItem.Variant1Name  :""] :bannerDataItem.Variant1Value,
        ...(bannerDataItem.Variant2Name  !== "" && {  [ bannerDataItem.Variant2Name ] : bannerDataItem.Variant2Value }),
        "USIN": bannerDataItem.USIN ? bannerDataItem.USIN :"" ,
          "Product ID": bannerDataItem.ProductId,
         "Banner URL": bannerDataItem.WebImage,
         "Index" : index,
         "Screen Name":storeName+" Advertistement Banner "
       });
  
   }
 else if(bannerDataItem.NavigationType === "sub_category"){

    moengage.track_event("Offer_Store_Banner_Viewed", {
        "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
         "Category Name" : bannerDataItem.ParentCategoryName ,
         "Sub Category Name" : bannerDataItem.SubCategoryName,
         "Banner URL": bannerDataItem.WebImage,
         "Index" : index,
         "Screen Name":storeName+" Advertistement Banner"
       });
  
     }
     //console.log(urlT)
     //debugger
   //  setUrl( urlT)
}

  return (
    <div className={`product-card`}>
      <div className="card-wrapper" onClick={() => handleBannerClick()}>
        <Link className="card-item" to={url} >
          {isMobile && (
            <img src={product.Image} className="offerBanner" />
          )}
          {isDesktop && (
            <img src={product.WebImage} className="offerBanner" />
          )}
        </Link>
      </div>
    </div>
  );
};

export default BannerCard;
