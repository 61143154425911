import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageComponent from '../image/mainImageComp';
import Loader from '../loader/loadingCard'
import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";
// import { PRODUCTDETAIL } from "../../../../Constants";
import { useMediaQuery } from 'react-responsive';

// import { ReactComponent as LeftArrow } from '../../../../assests/icons/LeftArrow.svg';
import { Link } from "react-router-dom";

const DefaultSliderImages = [
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 1',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 2',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 3',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 4',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  },
  {
    mobileSrc: '/images/mobile-banner.png',
    desktopSrc: '/images/banner1.png',
    alt: 'New Image 5',
    productId: '',
    Variant1Value: '',
    Variant2Value: ''
  }
];
let banners;
const OfferStoreBannerSlider = ({data,name}) => {
  const [sliderImages, setSliderImages] = useState(DefaultSliderImages);
  const [isLoading, setIsLoading] = useState(true);
  //const [dailyDeals, setDailyDeals] = useState([]);

  useEffect(() => {
    let isMounted = true; // track if component is mounted
    const loadBannerData = async () => {
      try {

       banners= data;
        if (banners.length > 0) {
          const formattedImages = banners.map(banner => ({
            mobileSrc: banner.OfferBanner?.trim() || '/images/default-mobile.png',
            desktopSrc: banner.OfferBanner?.trim() || '/images/default-desktop.png',
            id:banner.id,
   
            alt: banner.OfferTitle || 'Default Image',
        
       
          }));
          if (isMounted) {
            setSliderImages(formattedImages);
            setIsLoading(false);
          }
        //  console.log(formattedImages)
        }
      } catch (error) {
        console.error('Error fetching banner data:', error);
        if (isMounted) {
          setSliderImages(DefaultSliderImages);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    loadBannerData();

    return () => {
      isMounted = false; // cleanup function to set isMounted to false when the component unmounts
    };
  }, []); // empty dependency array to run only once on mount


  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const sliderSettings = {
    dots: false,
    arrows: true,
    rtl: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.45,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.45,
          slidesToScroll: 1,
          dots:false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots:true
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        }
      }
    ]
  };
//console.log(sliderImages)

const handleMoenagege = (image) => {
  console.log(image)


  moengage.track_event("Homepage_Store_Offers_Viewed", {
    "ID": (Cookies.get("Otp")) ? `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` :"", 
    "Store ID" :image.id ,
    "Store Name" :image.OfferTitle ,
    "Screen Name" :image.OfferTitle ,

   });
  
}




  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another way
    }
    return encodeURIComponent(
      str
        .replace(/[^a-zA-Z0-9.]/g, "-") // Replace non-alphanumeric characters with hyphens
        .replace(/-+/g, "-")            // Replace multiple hyphens with a single hyphen
        .replace(/^-|-$/g, "")          // Remove leading or trailing hyphens
        .toLowerCase()                  // Convert to lowercase
    );
  };

  // console.log(sliderImages,"sliderImages")

  return (
    <div> {banners &&  (
      <div className="offer__banner__wrapper section_space">
      <div className="offer__banner__container amazing__brand page-width">
        <div className="hm__prod__slider-title less_space_title">
          <h2>{name}</h2>
          {/* <div className="hm__prod__mobile__arrow"><LeftArrow /></div> */}
        </div>
      </div>
      <div className="banner_sl_container">
        {isLoading ? (
          <div className="loading-spinner">
            <Loader />
          </div>
        ) : (
          <Slider {...sliderSettings} className="cm_banner_slider cm_arrow_side">
   
           {sliderImages.map((image, index) => {
          
            return (
              <div key={index} onClick={() => handleMoenagege(data[index])}>
                {image.id && (
                  <Link
                  to={`/offer_store?id=${image.id}`}
                  >
                    <ImageComponent
                      src={isMobile ? image.mobileSrc : image.desktopSrc}
                      alt={image.alt}
                      height={'320px'}
                      pageWidth={isMobile ? "100%" : "1200px"}
                    />
                  </Link>
                )}
              </div>
            );
          })}

          </Slider>
        )}
      </div>
    </div>)}</div>
  );
}

export default OfferStoreBannerSlider;
