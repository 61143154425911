import React, { useState } from "react";
import './Content.css';
import DownCircleIcon from "./../assests/icons/DownCircleIcon.png"

const HomeContent = () => {
  const [isExpaneded, setIsExpanded] = useState(true);

  const handleExpansion = () => {
    setIsExpanded(!isExpaneded);
  };

  return (
    <div className="home-content-wrapper page-width">
        <div  className="home-content-container ">
        
      <h1>
      A Leading Online Supplements Store for Health, Nutrition & Bodybuilding
      </h1>

      <p className= {isExpaneded ? '' : "view-more"}  >
        Welcome to Unfit Mart, your premier destination for top-quality health,
        nutrition, and bodybuliding suppliments. We are dedicated to providing
        our wide range of products to assist you in reaching your wellness and
        fitness objectives. Whether you're an athlete, a fitness enthusiast, or
        someone looking to improve overall health. Unfit Mart has everything you
        need.
      </p>
     

      {isExpaneded && (
        <div>
     
          <h2>Why Choose Unfit Mart?</h2>
          <h3>At Unfit Mart, we pride ourselves on offering:</h3>
          <ul>
          <li><strong>High-quality Products:</strong> To guarantee that you get only the best, we source our products from reliable brands.</li>
  
        <li><strong>Wide Range of Categories:</strong> From sports nutrition to healthy foods, we have a comprehensive selection to cater to all your health and fitness needs.</li>
    
        <li><strong>Expert Guidance:</strong> Our team of experts is always ready to provide advice and support to help you make informed choices.</li>
      
        <li><strong>Convenient Shopping Experience:</strong> Finding and buying the things you need is simple with our user-friendly website.</li>
       
        <li><strong>Competitive Prices:</strong> We offer great value for money, ensuring you get the best deals on top-quality supplements.</li>
          
          </ul>
      


          <h2>Explore Our Product Range</h2>
          <h3><a href="https://unfitmart.com/category/Sports+Nutrition?viewId=1" target="_blank">Sport Nutritions </a></h3>
          <ul>
          <li><strong>Whey Protein:</strong> Essential for muscle recovery and growth, our whey protein range includes various flavors and formulations to suit your taste and dietary needs</li>
       
        <li><strong>Protein Blend:</strong> A mix of different protein sources to provide a sustained release of amino acids, perfect for any time of the day.</li>
      
        <li><strong>Whey Protein Isolate:</strong> High in protein and low in carbs and fats, ideal for those looking to build lean muscle.</li>
      
        <li><strong>Gainers:</strong> Designed to help you gain weight and muscle mass, our gainers are packed with calories and nutrients.</li>
        
        <li><strong>Pre-Workout:</strong> Boost your energy and performance with our range of pre-workout supplements.</li>
       
        <li><strong>Amino Acids:</strong> Support muscle recovery and reduce fatigue with our amino acid supplements.</li>
       
        <li><strong>Protein Bars:</strong> A convenient and tasty way to get your protein fix on the go.</li>
      
        <li><strong>Plant / Vegan Protein:</strong> High-quality protein sources for those following a plant-based diet.</li>
          </ul>

          <h3><a href="https://unfitmart.com/category/Health+Supplement?viewId=2" target="_blank">Health Supplements</a></h3>
          <ul>
          <li><strong>Multivitamins:</strong> Make sure your body is getting all the vital vitamins and minerals it requires.</li>

        <li><strong>Omega 3 & Fish Oil:</strong> Support heart health and reduce inflammation with our omega-3 supplements.</li>
     
        <li><strong>Pre & Probiotics (Gut Health):</strong> Promote a healthy digestive system with our range of pre and probiotics.</li>
     
        <li><strong>Melatonin (Sleep Care):</strong> Improve your sleep quality with our melatonin supplements.</li>
          </ul>
          <h3><a href="https://unfitmart.com/category/Hair,+Skin+&+Nails?viewId=3" target="_blank">Hair, Skin & Nails</a></h3>
          <ul>
          <li><strong>Biotin:</strong> Support healthy hair, skin, and nails with our biotin supplements.</li>
   
        <li><strong>Glutathione:</strong> A potent antioxidant that promotes skin health and aids in the body's detoxification.</li>
      
        <li><strong>Collagen:</strong> Promote skin elasticity and joint health with our collagen supplements.</li>
      
        <li><strong>Other Beauty Supplements:</strong> Enhance your natural beauty with our range of beauty supplements.</li>
          </ul>
          <h3><a href="https://unfitmart.com/category/Hair,+Skin+&+Nails?viewId=3" target="_blank">Health Foods and Drinks</a></h3>
          <ul>
          <li><strong>Oats And Muesli:</strong> Start your day with a nutritious and delicious breakfast.</li>
       
        <li><strong>Peanut butter:</strong> A fantastic source of good fats and protein.</li>
      
        <li><strong>Nuts & Raisins:</strong> A healthy snack option packed with nutrients.</li>
       
        <li><strong>Trail mixes:</strong> Perfect for a quick and healthy snack on the go.</li>
      
        <li><strong>Edible seeds:</strong> Add a nutritional boost to your meals with our range of edible seeds.</li>
       
        <li><strong>Healthy Snacks:</strong> Satisfy your cravings with our selection of healthy snacks.</li>
      
        <li><strong>Instant Mixes:</strong> Convenient and nutritious meal options.</li>
     
        <li><strong>Kids nutrition:</strong> Ensure your kids get the nutrients they need with our range of kids’ nutrition products.</li>
          </ul>
       
          <h3><a href="https://unfitmart.com/category/Diet+and+Nutrition+?viewId=5" target="_blank">Diet and Nutrition</a></h3>
          <ul>
          <li><strong>Slimming and Detox:</strong> Support your weight loss goals with our slimming and detox products.</li>
  
        <li><strong>ACV:</strong> Apple cider vinegar supplements to aid digestion and weight management.</li>
      
        <li><strong>Resistance Bands & Tummy Trimmer:</strong> Enhance your workouts with our range of fitness accessories.</li>
        <li><strong>Sugar Substitutes:</strong> Enjoy your favorite foods without the extra calories with our sugar substitutes.</li>
          </ul>
          <h3><a href="https://unfitmart.com/category/Fitness+Accessories?viewId=8" target="_blank">Fitness Accessories</a></h3>
          <ul>
          <li><strong>Active Wear:</strong> Comfortable and stylish activewear for all your fitness activities.</li>
    
        <li><strong>Yoga Mats & Accessories:</strong> Everything you need for a great yoga session.</li>
       
        <li><strong>Resistance Bands & Tummy Trimmer:</strong> Enhance your workouts with our range of fitness accessories.</li>
      
        <li><strong>Shakers, Gloves and Grips:</strong> Essential accessories for your fitness routine.</li>
          </ul>
          <h3><a href="https://unfitmart.com/category/Women's+Health?viewId=7" target="_blank">Wonem's Health</a></h3>
          <ul>
          <li><strong>Protein for Women:</strong> Specially formulated protein supplements to meet the unique needs of women.</li>
      
        <li><strong>Prenatal & Post Natal Vitamins:</strong> Ensure you and your baby get the nutrients you need during and after pregnancy.</li>
          </ul>
          <h3><a href="https://unfitmart.com/category/Ayurvedic+&+Herbal+Supplements?viewId=6" target="_blank">Ayurvedic & Herbal Supplements</a></h3>
          <ul>
          <li><strong>Shilajit:</strong> A powerful natural supplement to boost energy and vitality.</li>
  
        <li><strong>Immunity care:</strong> Support your immune system with our range of herbal supplements.</li>

        <li><strong>Ashwagandha:</strong> Reduce stress and improve overall health with our ashwagandha supplements.</li>
       
        <li><strong>Chyawanprash:</strong> A traditional Ayurvedic supplement to support overall health and wellness.</li>
          </ul>
          <p className="orange">At Unfit Mart, we are committed to helping you achieve your health and fitness goals with our wide range of high-quality products. Shop with us today and experience the Unfit Mart difference!</p>
          </div>
           )}
          
    
      <button onClick={handleExpansion} className="view-more-btn">
        {isExpaneded ? "View Less" : "View More"} <img className={`icon ${isExpaneded ?  "rotate-180" : ""}`} src={DownCircleIcon} />
      </button>
    </div>
    </div>
  );
};

export default HomeContent;
