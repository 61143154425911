// src/api/ProductListApi.js
import { BLOGPOST,RECOMMENDEDBLOG } from "../Constants";
const BASE_URL =  BLOGPOST;

// Caching and promise management
let cachedBlogData = null;


// Fetch Blog List Data
export const fetchBlogListData = async (page) => {
  





    try {
      const payload = new URLSearchParams({
        Pagination: '',
        groupCategoryId: '',
        LevelType: ''
      });

      const response = await fetch(BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: payload,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      //console.log(data)
  return data
    } catch (error) {
      console.log(error);
    }

 
};
let cachedRecommenedBlogData = null;

export const fetchRecommenedBlogListData = async () => {
 
  let  recommenedBlogData
  if (cachedRecommenedBlogData) {
    return cachedRecommenedBlogData;
  }
try{


      const response = await fetch(RECOMMENDEDBLOG, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
     
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      recommenedBlogData = data.Result || [];
   
      cachedRecommenedBlogData=recommenedBlogData
    } catch (error) {
      console.log(error);
    } 
 

  return recommenedBlogData;
};


