import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import CustomLoader from "../../common/customLoader/customLoader";
import { PRODUCT_LIST } from "../../../Constants";
import ProductCard from "./productCard/productCard";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchParentCategoryProductList } from "../../../api/fetchParentCategoryProductList";
import {
  data_not_found,
} from "../../../utils/image";
import BrandDetail from "../../common/brands/brandDetail";
import HomeContent from "../../../pages/HomeContent";
import BoltBrandContent from "../../../pages/BoltBrandContent";
import MetaTagsComponent from "../metaTag/metaTagComp";
const ProductList = ({ setCountItems }) => {
  const [page, setPage] = useState(1);
  const [dummyPage,setDummyPage] = useState(0)
  const [products, setProducts] = useState([]);
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentSelected, setCurrentSelected] = useState([]);
  const observer = useRef();
  const location = useLocation();
  // let categoryName = decodeURIComponent(
  //   location.pathname.split("/").filter(Boolean).pop().replace(/\+/g, " ")
  // );
  let path = location.pathname.split("/").filter(Boolean);
  //console.log(path)
  const [searchParams] = useSearchParams();
let parentCategoryName,subCategoryName,categoryId,brandName,brandId;
  switch (path[0]) {
    case 'category':
       parentCategoryName = decodeURIComponent(path[1].replace(/\+/g, " "));
       subCategoryName = path[2] ? decodeURIComponent(path[2].replace(/\+/g, " ")) : "";
       categoryId = subCategoryName ? searchParams.get("id") : searchParams.get("viewId");
     // console.log(parentCategoryName, subCategoryName,categoryId);
    
      break;
    case 'brands':
      brandName = decodeURIComponent(path[1].replace(/\+/g, " "));
      categoryId = searchParams.get("id");
      brandId = categoryId
    

       
        break;
    case 'best-seller':
      categoryId = searchParams.get("id");
       
        break;
    case 'grape':
        
        break;
    default:
        
}




  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };

  const fetchCategoryFromApi = async () => {
    const payload = {
      Pagination: page,
      ParentCategoryName: parentCategoryName,
      LevelType: "groupCategory",
      groupCategoryId: categoryId,
      ApplicationType: "web",
    };

    try {
      const response = await fetch(PRODUCT_LIST, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(payload).toString(),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("Error fetching data"); // Rethrow error for handling at the call site
    }
  };
  const isMounted = useRef(false);
  
useEffect(() => {
 // console.log(dummyPage,page)
  if(page === 1 ){
    setDummyPage(dummyPage+1)

  }
  setPage(1)
  setProducts([])
 // console.log(dummyPage,page)

  
  
},[categoryId])


  useEffect(() => {
    const loadProducts = async () => {
      //if (loading) return;
      setLoading(true);
      try {
        const response = await fetchParentCategoryProductList(
          parentCategoryName,
          subCategoryName,
          categoryId,
          page,
          path[0]
        );
        //console.log(response);
        const data = response.Result;
        if (response.Status) {
          setProducts((prev) => [...prev, ...data[0]]);
          
        }
        setResponse(response)
       // console.log(data[0]);
        //console.log(products);
        setHasMore(data.length > 0);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
     

    if (!isMounted.current && !loading) {
      isMounted.current = true;
      //console.log(page)
      //loadProducts();
    } else if (!loading) {
      //console.log(page)
      loadProducts();
    }
    console.log(page)
  }, [page,dummyPage]);

  const lastProductElementRef = useRef();
  const observerCallback = (entries) => {
    if (entries[0].isIntersecting && hasMore && !loading) {
      setPage((prev) => prev + 1);
    }
  };
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.1,
  };
  useEffect(() => {
    const currentObserver = observer.current;
    if (currentObserver) {
      currentObserver.disconnect();
    }

    observer.current = new IntersectionObserver(observerCallback, options);
    if (lastProductElementRef.current) {
      observer.current.observe(lastProductElementRef.current);
    }

    return () => {
      if (currentObserver) {
        currentObserver.disconnect();

      }
    };
  }, [options]);
//console.log(response.BrandDetails)
  return (
         <div className="brand-detail">
          <MetaTagsComponent/>
     <div className="brand__details__filter">
    <div className="brand__filter__wrapper page-width">
      <main className="brand__product-list">
        { path[0] === "brands" &&  response && <BrandDetail setCountItems={setCountItems} brandsDetailsData={response.BrandDetails} />}
        {path[0] !== "brands"  &&<div className="brand__product-list-header">
          <div className="brand__item_count">
            {/* <span className="brand__count"> {brandsData ? brandsData.length : 0} </span>items in */}
            <h1 className="brand__count__name">
              {/* {decodeURIComponent(selectedData.subCategory.toUpperCase() ||
                selectedData.categoryName.toUpperCase())} */}
              {path[0] !==  "best-seller"? (subCategoryName ? subCategoryName :parentCategoryName) : "Best Sellers"}
            </h1>
          </div>
        </div>}
        <div
          className={`brand__product-grid grid__view  no_filter grid__view `}
        >
          {products && products.map((product, index) => {
            if (products.length === index + 1) {
              
              return (
                
                  <ProductCard
                    key={index}
                    product={product}
                    setCountItems={setCountItems}
                    onAddtoCart={handleInitialAddToCart}
                    currentSelected={currentSelected}
                    isOutOfStock={product?.VariantDetails?.[0]?.InStock == "no"}
                    from={"Particular_Product_Viewed"}
                    screenName={parentCategoryName}
                    sendRef={lastProductElementRef}
                  />
              
              );
            } else {
              return (
                <ProductCard
                  key={index}
                  product={product}
                  setCountItems={setCountItems}
                  onAddtoCart={handleInitialAddToCart}
                  currentSelected={currentSelected}
                  isOutOfStock={product?.VariantDetails?.[0]?.InStock == "no"}
                  from={"Particular_Product_Viewed"}
                  screenName={parentCategoryName}
                />
              );
            }
          })}
        </div>
        {loading && <CustomLoader />}
        {!loading && products.length == 0 &&   <div className={`not_data_found_wrap ${products.length ? "hide": "active"}`}
                  style={{ width: "100%" }}
                >
                  <div className="main_data_container">
                    <div className="img_found_wrap">
                      <img src={data_not_found} alt="img" />
                    </div>
                    <div className="not_found_text">
                      <h5>Oops.. No Data Found!</h5>
                    </div>
                  </div>
                </div>}
        { products && path[0]==="brands" && searchParams.get("id") == 21 && <BoltBrandContent/>      }
      </main>
    </div>
    </div>
    </div>
  );
};

export default ProductList;
