import React from "react";
import ratingStarIcon from "../../assests/icons/ratingStar.png";
import locationIcon from "../../assests/icons/Location.png"
import callIcon from "../../assests/icons/Call.png"
import { Link } from "react-router-dom";
function StoreBox({ store }) {



	const handleOpenGoogleMaps = ( endLat, endLng) => {
		const googleMapsUrl = `https://www.google.com/maps/dir//${endLat},${endLng}`;
		window.open(googleMapsUrl, '_blank'); // Open in a new tab
	  };
 // console.log(store);
 const handleClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <Link to={`/unfit-store/${store.City}?storeId=${store.id}`}>
    <div className="store-box-container">
      <img src={store?.MediaFile} className="store-image" />
      <div className="store-detail">
        <div className="store-header">
          <h5>{store?.StoreName}</h5>
          <div className="rating-star">
            <img src={ratingStarIcon} /> <p>{store.Rating ? store.Rating : "4.3"}</p>
          </div>
        </div>
	

        <p>{store?.Address.length < 75 ? store?.Address : store?.Address.slice(0,75) +"..."}</p>
		<div className="store-hours">
			<p>Store Hours:-</p>
			<div className="p2">{store.StoreHours}</div>
		</div>
		<div className="btn-container">
			<button className="product_atc_btn" onClick={() => handleOpenGoogleMaps( store.Latitude, store.Longitude)}><img src={locationIcon} className="icon" />Location</button>
			<button className="product_atc_btn" onClick={() => handleClick(store.PhoneNumber.slice(3))}><img src={callIcon} className="icon"/>{store.PhoneNumber.slice(3)}</button>
		</div>
      </div>
    </div>
    </Link>
  );
}

export default StoreBox;
