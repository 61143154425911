import React, { useEffect, useState } from "react";
import "./unfitStore.css";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import { useLoadScript } from "@react-google-maps/api";
import GoogleMapReact from "google-map-react";
import storePinIcon from "../../assests/image/storePinIcon.png";
import GoogleMapsDisplay from "./GoogleMapsDisplay";
import { useDispatch, useSelector } from "react-redux";
import { setCity,setCityList } from "../../redux/slice";
import { fetchMarkerList } from "./fetchUnfitStoreApi";
import StoreBox from "./StoreBox";
const UnfitStorePage = () => {

  const [cityValue, setCityValue] = useState('All');
	const cityList = ["All" ,...useSelector((state) => state.filters.cityList)]; // Access state
  const mList = useSelector((state) => state.filters.markerList); 
//console.log(cityList)

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setCityValue(newValue);
    dispatch(setCity(newValue))
   // console.log(newValue)
  };


let response
  const loadCity = async () => {
    //setLoading(true);
    try {
   
		response = await fetchMarkerList();
	 // console.log("response : ",response)
	   dispatch(setCityList(response))

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
     // setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {

    loadCity();

  },[])


	const dispatch = useDispatch(); // Access dispatch function
 let variant1Options= ['All','Ahmedabad','Surat','Rajkot'];
  return  (
    <div className="flex page-width store-list-container">
      <div className="left-contanier">
        {/* <p>120 stores</p> */}
        <h3>unFit Store in {cityValue == "All" ? "India" : cityValue}</h3>
        <select value={cityValue} onChange={handleCityChange}>
          {cityList.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>

        <div className="store-list-wrapper">
          <div className="store-box">
            {
              mList && mList.map((store,index) => (
                <StoreBox store={store} key={index}/>
              ))
            }
            
           
          </div>
        </div>
      </div>
      <div className="google-maps-contanier">
        {" "}
  <GoogleMapsDisplay />
      </div>
    </div>
  )
};

export default UnfitStorePage;
