import React from "react";
import startIcon from "../../assests/icons/ratingStar.png";
import "./unfitStorePage2.css";
import CallIcon from "../../assests/icons/CallOrange.png"
import Location from "../../assests/icons/LocationIcon.png";
import MsgIcon from "../../assests/icons/Message.png"
import img1 from "../../assests/image/Group 4534383.png";
import img2 from "../../assests/image/Group 4534384.png";
import img3 from "../../assests/image/Group 1000002135.png";
import { ReactComponent as Line } from "../../assests/icons/line.svg";
function StoreDetailPage() {
  return (
    <div className="page-2-container ">
      <div className="page-width ">
        <div className="page-2-wrapper ">
          <div className="page-width detail-contanier ">
            <div className="store-title-wrapper ">
              <h3>unFit Bopal Store</h3>
              <div className="rating-box">
                <img alt="star" className="icon" src={startIcon} />
                <p>4.5 (4.8 reviews)</p>
              </div>
            </div>
            <div className="blog__post__line">
            <Line />
          </div>
            <div className="product-wrapper ">
              <h3>Products</h3>
              <div className="product-container ">
                <div className="product-box "></div>
              </div>
            </div>
            <div className="payments-wrapper "></div>
          </div>
          <div className="store-timing-container  ">
            <div class="store">
              <div class="store-info">
                <div className="store-info-details">
                  <img
                    src={Location}
                    alt="Location Icon"
                  />
                  <p>
                    22,2701, Celebration City, Gym Khana Road, South Bopal,
                    Ahmedabad, Gujarat
                  </p>
                </div>
                <div className="store-info-details">
                  <img
                    src={CallIcon}
                    alt="Location Icon"
                  />
                  <p>+91 9898256556</p>
                </div>
                <div className="store-info-details">
                  <img
                    src={MsgIcon}
                    alt="Location Icon"
                  />
                  <p>bopalstore@unfitmart.com</p>
                </div>
              </div>
              <div className="blog__post__line">
            <Line />
          </div>
              <div class="store-timing">
                <h3>Store Timing</h3>
                <p>Timing: 9:00 AM to 9:00 PM</p>
                <div className="blog__post__line">
            <Line />
          </div>
                <form>
                  <label>
                    <input type="radio" name="day" value="Sunday" />  Sunday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Monday" /> Monday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Tuesday" /> Tuesday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Wednesday" />
                    Wednesday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Thursday" /> Thursday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Friday" /> Friday
                  </label>
                  <label>
                    <input type="radio" name="day" value="Saturday" /> Saturday
                  </label>
                </form>
              </div>
              <div class="logos">
                <img
                  src={img1}
                  alt="Logo 1"
                />
                <img
                  src={img2}
                  alt="Logo 2"
                />
                <img
                  src={img3}
                  alt="Logo 3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreDetailPage;
