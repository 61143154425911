import React, { useState, useEffect, useCallback, useMemo } from "react";
import Cookies from 'js-cookie';
import { fetchExistingAddress } from "../../../../api/fetchAddressApi";
import updateAddressApi from "../../../../api/updateAddressApi";
import { cross_black } from "../../../../utils/image";
import { fetchUserAddress, fetchUserData } from "../../../../Constants";
import { validateAddressForm } from "./validate";
import moengage from "@moengage/web-sdk";
const UpdateAddress = ({ setDisplayLoginPages, displayLoginPages, setAddress }) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [msg,setMsg] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("IN"); // Default to India
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [addressFormData, setAddressFormData] = useState({
    building: '',
    street: '',
    landmark: '',
    pincode: '',
    state: '',
    city: '',
    country: ''
  });
  const [isEditAddress, setisEditAddress] = useState(false);
  const [errors, setErrors] = useState({});

  let modal = document.getElementById('myModal')

  const config = useMemo(() => ({
    cUrl: 'https://api.countrystatecity.in/v1',
    ckey: 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA=='
  }), []);

  const loadCountries = useCallback(() => {
    fetch(`${config.cUrl}/countries`, {
      headers: {
        'X-CSCAPI-KEY': config.ckey,
      }
    })
      .then(response => response.json())
      .then(data => {
        const india = data.find(country => country.iso2 === 'IN');
        if (india) {
          setCountries([india]);
          setSelectedCountry(india.iso2); // Set India as the default selected country
        }
      })
      .catch(error => console.error('Error loading countries:', error));
  }, [config]);

  useEffect(() => {
    loadCountries();
  }, [loadCountries]);

  const loadStates = useCallback((countryCode) => {
   // console.log(`Fetching states for country: ${countryCode}`);
    fetch(`${config.cUrl}/countries/${countryCode}/states`, {
      headers: {
        'X-CSCAPI-KEY': config.ckey,
      }
    })
      .then(response => response.json())
      .then(data => {
        setStates(data);
      })
      .catch(error => console.error('Error loading states:', error));
  }, [config]);

  useEffect(() => {
    if (selectedCountry) {
      loadStates(selectedCountry);
    }
  }, [selectedCountry, loadStates]);

  const loadCities = useCallback((countryCode, stateCode) => {
  //  console.log(`Fetching cities for country: ${countryCode}, state: ${stateCode}`);
    fetch(`${config.cUrl}/countries/${countryCode}/states/${stateCode}/cities`, {
      // fetch(`${config.cUrl}/countries/${countryCode}/states/GJ/cities`, {
      headers: {
        'X-CSCAPI-KEY': config.ckey,
      }
    })
      .then(response => response.json())
      .then(data => {
        setCities(data);
      })
      .catch(error => console.error('Error loading cities:', error));
  }, [config]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  //  console.log(e.target.name)
  if(value == ''){
    setErrors({
      ...errors,
      [name]:"Required"
    })
  }
  else{
    setErrors({
      ...errors,
      [name]:''
    })
  }
    if(name === 'pincode' && value.length !== 6){
      setMsg(true)
    }
    setAddressFormData({
      ...addressFormData,
      [name]: value
    });
    // console.log("handleInputChange",value)
    //console.log(addressFormData)
  };

  

  const handleStateChange = (event) => {
    const stateCode = event.target.value;

    setSelectedState(stateCode);
    setSelectedCity('');
    loadCities(selectedCountry, stateCode);
    setAddressFormData({
      ...addressFormData,
      [event.target.name]: event.target.value
    })
    // console.log("handleStateChange",event.target.value)
    //setSelectedCity(event.target.value);
    handleInputChange(event)
  };



  const handleUpdateAddress = () => {

    

    //setting addressFormData
    setAddressFormData({
      ...addressFormData,
      state: selectedState,
      //city: selectedCity,
      country: selectedCountry || 'India'
    })
    //console.log(addressFormData);
    if(validateAddressForm(addressFormData,setErrors)){
    if (displayLoginPages) {
      handleClose();
      // console.log(addressFormData);
      setAddress(addressFormData)
    }
    saveData();
    setNewAddress();
  }
  }
  const setNewAddress = async () => {
    try {
     //  console.log(addressFormData);
      const result = await updateAddressApi(addressFormData);
     // console.log(result);
     moengage.track_event("Address_Page_Viewed", {
      "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` ,  
      "Flat, House no, Building":  addressFormData.building,
     " Area, street, sector": addressFormData.street,
      "Landmark": addressFormData.landmark,
      "Pincode": addressFormData.pincode      ,
      "State": addressFormData.state,
      "District/city": addressFormData.city,
      "Country": addressFormData.country || 'India'

      });

    } catch (error) {
      console.error('Error fetching data:', error);

    }
    finally{toggleEditAddress()}
  };


  const saveData = () => {
    Cookies.set('userAddress', JSON.stringify({
      number: addressFormData.building,
      street: addressFormData.street,
      landmark: addressFormData.landmark,
      pincode: addressFormData.pincode,
      state: addressFormData.state,
      city: addressFormData.city,
      country: addressFormData.country || 'India'
    }));
  };

  let span;

  useEffect(() => {
    span = document.getElementsByClassName("close")[0];
    const fetchAddress = async () => {

      try {
        const result = await fetchExistingAddress();
       //console.log(result);
        if (result.HouseNumberAndStreet) {
          setSelectedState(result.State);
          loadCities(selectedCountry, result.State);
          //console.log(result.City);
          setSelectedCity(result.City);

          setAddressFormData({
            building: result.HouseNumberAndStreet.split('|')[0],
            street: result.HouseNumberAndStreet.split('|')[1],
            landmark: result.Landmark,
            pincode: result.PostalCode,
            state: result.State,
            city: result.City,
            country: result.Country || 'India'

          })

         

        }

      } catch (error) {
        console.error('Error fetching data:', error);

      }
    };
    fetchAddress()
  }, []);
  const handleClose = () => {
    modal.style.display = "none";
    setDisplayLoginPages({
      ...displayLoginPages,
      displayAddressPage: false
    });
  };
 // console.log('cities', cities)
  const toggleEditAddress = ()=>{
    setisEditAddress(!isEditAddress)
  }
  const userData = fetchUserData()
  const userAddress = fetchUserAddress()
 // console.log('userData', userAddress)
  useEffect(() => {
    
  
  if(!userAddress){

    setisEditAddress(true)
  }
  }, [])
  
  return (
    <div className="address__info">
      <div className="account_page_title">
        <div className="address__main__heading">
          <h5>Address Information</h5>
        </div>
        <span className="close" onClick={handleClose}>
          <img src={cross_black} alt="close"/>
        </span>
      </div>
      <div className='account_card_right'>
        <div className="address_details_main" style={{display: !isEditAddress && userAddress ?"none":"block"}}>
          <form className="addres__update account__form">
            <div className="account__phone__box">
              <div className="account__phone__head">Flat, House no, Building <span className="red">*</span></div>
              <input required type="text" placeholder="House no" name="building" id="building" value={addressFormData.building}
                onChange={handleInputChange} />
              {errors.building  &&  <span className="red">Flat, House no, Building is {errors.building}</span>}
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">Area, street, sector<span className="red">*</span></div>
              <input type="text" placeholder="street" name="street" id="street" value={addressFormData.street}
                onChange={handleInputChange} required />
              {errors.street  &&  <span className="red">Area, street, sector is {errors.street}</span>}
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">Landmark</div>
              <input type="text" placeholder="Landmark" name="landmark" id="landmark" value={addressFormData.landmark}
                onChange={handleInputChange} required />
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">Pincode<span className="red">*</span></div>
              <input type="number" placeholder="Pincode " name="pincode" id="pincode" value={addressFormData.pincode}
                onChange={handleInputChange} required />
              {errors.pincode  &&  <span className="red">Pincode is {errors.pincode}</span>}
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">State<span className="red">*</span></div>
              <select className="select__gender" onChange={handleStateChange} value={addressFormData.state} name="state" required>
                <option key="defaultState" value="">Select State</option>
                {states.map((state, index) => (
                  <option key={`${state.iso2}-${index}`} value={state.iso2}>{state.name}</option>
                ))}
              </select>
              {errors.state  &&  <span className="red">State is {errors.state}</span>}
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">District/city<span className="red">*</span></div>
              <select className="select__gender" onChange={handleInputChange} value={addressFormData.city} name="city" required>
                <option key="defaultCity" value="">Select City</option>
                {!cities?.error && cities?.map((city, index) => (
                  <option key={`${city.iso2}-${index}`} value={city.iso2}>{city.name}</option>
                ))}
              </select>
              {errors.city  &&  <span className="red">City is {errors.city}</span>}
            </div>
            <div className="account__phone__box">
              <div className="account__phone__head">Country<span className="red">*</span></div>
              <select className="select__gender" onChange={handleInputChange} value={addressFormData.country} name="country" required>
                {countries.map((country, index) => (
                  <option key={`${country.iso2}-${index}`} value={country.iso2}>{country.name}</option>
                ))}
              </select>
            </div>
          </form>
          <div className="account__edit__btn" onClick={handleUpdateAddress}>
            Update Address
          </div>
        </div>
        {/* {console.log('isEditAddress && !userAddress', isEditAddress && !userAddress)} */}
       {userAddress && 
        <div className="address_card_main" style={{display: (isEditAddress)?"none":"block"}}>
          <h6>Default Shipping Address</h6>
          <address>
           {userAddress.number},{userAddress.street}<br />
            {userAddress.landmark}<br />
            {userAddress.city}, {userAddress.pincode}<br />
            {userAddress.state}, {userAddress.country}<br />
            {userData?.MobileNo}
          </address>
          <div className="address_action">
            <button className="link_btn delete_add" onClick={toggleEditAddress}>Edit</button>
            {/* <button className="link_btn delete_add">Delete</button> */}
          </div>
        </div>
       }
      </div>
    </div>
  );
};

export default UpdateAddress;
