import React from 'react';
import "./orderHistoryItem.css";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function OrderStatusItem({ orderDetail }) {
  const navigate = useNavigate();
  const fixUrlString = (str) => {
    if (typeof str !== 'string') {
      return ''; // or handle it in another wayesimi
    }
    return encodeURIComponent(
      str
        .replace(/[^a-zA-Z0-9.]/g, "-") // Replace non-alphanumeric characters with hyphens
        .replace(/-+/g, "-")            // Replace multiple hyphens with a single hyphen
        .replace(/^-|-$/g, "")          // Remove leading or trailing hyphens
        .toLowerCase()                  // Convert to lowercase
    );
  };

let orderDetailClick = () => {
 let productNewTabUrl = `${window.location.origin}/product/${orderDetail?.USIN}/${fixUrlString(orderDetail?.Title)}/${orderDetail?.variant1_value}/${orderDetail?.variant2_value}?productid=${orderDetail?.ProductId}`;
  window.open(productNewTabUrl, "_blank");
}

  return (
    <div className="orderDetailsWrapper">
      <div className="detailBox">
      <div className="reOrderWrapper">
        <div className="detailsContent">
          <div className="history__item__image">
            <img src={orderDetail.Image[0].ImageUrl} alt="No Image" className='imgShow'  loading="lazy"
             />
          </div>
          <div className="orderDetails">
            <div className="order__items__title" onClick={()=>orderDetailClick()}>{orderDetail.Title}</div>
            <div className="actual_price">₹{ orderDetail?.ProductQuantity ? orderDetail.Price/orderDetail?.ProductQuantity : orderDetail.Price }</div>
           {orderDetail.variant1_value &&  <div className="orderedDate">{orderDetail.variant1_value ? ` ${orderDetail.variant1_name} : ${orderDetail.variant1_value}`: '' } {orderDetail.variant2_value ? `, ${orderDetail.variant2_name} : ${orderDetail.variant2_value}`: '' }</div>}
            <div className="orderedDate">Quantity : {orderDetail?.ProductQuantity} </div>
          </div>
        </div>
        </div>
      </div>
    </div>

  )
}

export default OrderStatusItem




