import React, { useEffect, useRef, useState } from 'react'
import BrandBanner from '../components/common/brands/brandBanner'
import CustomLoader from '../components/common/customLoader/customLoader'
import ProductCard from '../components/layout/product/productCard/productCard';
import {  data_not_found} from "../utils/image";
import  {offerStoreApi,offerStoreBanner}  from '../api/offerStoreApi';
import BannerCard from '../components/layout/product/BannerCard';
import { useLocation } from 'react-router-dom';
import HmSlider from '../components/common/bannerSlider/homeSlider/hmSlider';

    const OfferStorePage = ({setCountItems}) => {

     
        const [offerStoreData,setOfferStoreData]=useState([]);
        const [offerStoreName,setOfferStoreName]=useState([]);
        const [offerStoreBannerList,setOfferStoreBannerList] = useState([]);
        const [loading , setLoading] = useState(false);
        const [currentSelected, setCurrentSelected] = useState([]);
 const isMounted = useRef(false);
 const location = useLocation(); 
 const query = new URLSearchParams(location.search); 
 const id = query.get('id'); 



let offerStoreProductList,offerStoreBannerListt;


  const loadProducts = async () => {
    setLoading(true);
    try {
    
       offerStoreProductList = await offerStoreApi(id);
       offerStoreBannerListt = await offerStoreBanner();
       setOfferStoreBannerList(offerStoreBannerListt.Result);
       if(offerStoreProductList !== ""){
        setOfferStoreData(offerStoreProductList.Result);
        setOfferStoreName(offerStoreProductList.OfferTitle)
        
       }
       else {
        setOfferStoreData("");
       }
  
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Ensure loading is false after data fetch or error
    }
  };

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true; // Set isMounted to true after the first render
      loadProducts(); // Call loadProducts once on initial mount
    }
  }, []);

  const handleInitialAddToCart = (product) => {
    setCurrentSelected((prev) => {
      if (prev.includes(product.id)) {
        return prev.filter((each) => each !== product.id);
      } else {
        return [product.id];
      }
    });
  };

  return (
    <div>
       <HmSlider data={offerStoreBannerList} eventName={"Offer_Store_Banner_Viewed"} screenName={`${offerStoreName} Top banner`}/>;

        <main className="brand__product-list page-width">
          <div className="brand__product-list-header">
            <div className="brand__item_count">
           
              <h1 className="brand__count__name">
                {offerStoreName}
    
                {/* {decodeURIComponent(
                  location.pathname
                    .split("/")
                    .filter(Boolean)
                    .pop()
                    .replace(/\+/g, " ")
                )} */}
              </h1>
            </div>

          </div>    
          {/* loaderchanges */}
          {loading && <CustomLoader /> } 
          {!loading && (
          <div
          className={`brand__product-grid grid__view`}
        >
          <>
            {!loading && offerStoreData?.length > 0 ?  (
              <>
                {offerStoreData &&
                  offerStoreData.map(
                    (product,index) =>
                      product.Name && product?.Type === "product" ?  (
                        <ProductCard
                          key={product.id}
                          product={product}
                          setCountItems={setCountItems}
                          onAddtoCart={handleInitialAddToCart}
                          isOutOfStock={
                            product?.InStock == "no"
                          }
                          currentSelected={currentSelected}
                          from={"Particular_Product_Viewed"}
                          screenName={offerStoreName}
                        />
                        
                      ) : (
                        product &&  <BannerCard product={product} key={index} index ={index+1} storeName={offerStoreName}/>
                      )
                  )}
                
                  
              </>
            ) : (
              <>
                <div className={`not_data_found_wrap `}
                  style={{ width: "100%" }}
                >
                  <div className="main_data_container">
                    <div className="img_found_wrap">
                      <img src={data_not_found} alt="img" />
                    </div>
                    <div className="not_found_text">
                      <h5>Oops.. No Data Found!</h5>
                    </div>
                  </div>
                </div>
            </>
            ) }
          </>
        </div>
          )}
          {/* loaderchanges */}

        
        </main>
        
    </div>
  )
}

export default OfferStorePage
