import moengage from "@moengage/web-sdk";
import Cookies from "js-cookie";

const moengageEvent = (items,type) => {

    let ProductName = [],ProductID =[],BasePrice = [],PromotionPrice = [],Flavour=[],Size=[],Quantity=[],DiscountPercentage=[],USIN=[];
    console.log(items);
let variant1_name,variant2_name;
    items.Result.map((item) => {
        ProductName.push(item.PorductName);
        ProductID.push(item.ProductId)
        BasePrice.push(item.TotalMrp)
        PromotionPrice.push(item.TotalPrice)
        if(item.variant1_name === "Flavour")
        {Flavour.push(item.variant1_value)}
        else{
            Flavour.push(null)
        }
        Size.push(item.variant2_value)
        Quantity.push(item.ProductQuantity)
        DiscountPercentage.push(parseInt( item.Note.replace('%',"").split(" ")[1]))
        USIN.push(item.USIN ? item.USIN :"")
        variant1_name= item.variant1_name;
        variant2_name= item.variant2_name;
        moengage.track_event("Item_Purchased", {
            "ID": `+91${JSON.parse(Cookies.get("Otp")).MobileNo}` , 
            "Brand Name" :item.BrandName,
            "Product Name" :item.PorductName,
            "Product ID" : item.ProductId,
            "Base Price": item.TotalMrp,
            "Promotion Price" : item.TotalPrice,
            [item.variant1_name ? item.variant1_name  :""] :item.variant1_value,
            ...(item.variant2_name  !== "" && {  [item.variant2_name ] : item.variant2_value }),
            "USIN": item.USIN ? item.USIN :"" ,
            "Discount Percentage" :parseInt( item.Note.replace('%',"").split(" ")[1]), 
            "Quantity" : item.ProductQuantity,
            "OrderType" : type,
            "Customer Id" : JSON.parse(Cookies.get("userData")).id ? JSON.parse(Cookies.get("userData")).id : JSON.parse(Cookies.get("resigterId")).RegisterId,
            "Customer Name" : JSON.parse(Cookies.get("userData")).userName ? JSON.parse(Cookies.get("userData")).userName : JSON.parse(Cookies.get("userData")).firstName+" "+JSON.parse(Cookies.get("userData")).lastName,
            
            });



    })
  //console.log(ProductName)



    
}
export default moengageEvent;